<template>
  <div>
    <div class="main-navbar">
      <div id="mainnav">
        <OnClickOutside @trigger="close">
          <div class="toggle-nav" :class="leftSidebarVal ? 'toggle-button' : ''"
            @click="openmobilenav = !openmobilenav">
            <i class="fa fa-bars sidebar-bar"></i>
          </div>
          <ul class="nav-menu" :class="{ opennav: openmobilenav }">
            <li :id="`li-${menuItem.title}`" v-for="(menuItem, index) in menulist" :key="index"
              :class="menuItem.megamenu ? 'mega-menu' : 'dropdown'">
              <template v-if="menuItem.authOnly === false ||
                (menuItem.authOnly === true && isAuthenticated)
              ">
                 <div v-if="!menuItem.path" class="customLink" @click="setActive(menuItem.title)">
                   {{ $t(`menu.${menuItem.key}`, menuItem.title) }}
                   <span class="sub-arrow " v-if="menuItem.children || menuItem.megamenu"></span>
                 </div>

                <NuxtLinkLocale v-else :to="menuItem.path"  class="nav-link" @click="setActive(menuItem.title)">
                  {{ $t(`menu.${menuItem.key}`, menuItem.title) }}
                  <span class="sub-arrow " v-if="menuItem.children || menuItem.megamenu"></span>
                </NuxtLinkLocale>

                <!-- <a :href="menuItem.path" class="nav-link" @click="setActive(menuItem.title)">
                {{ $t(`menu.${menuItem.key}`, menuItem.title) }}
                <span class="sub-arrow" v-if="menuItem.children || menuItem.megamenu"></span>
              </a>
             <NuxtLink class="nav-link" @click="setActive(menuItem.title)" :to="{ path: menuItem.path }"> 
                {{ $t(`menu.${menuItem.key}`, menuItem.title) }}
                 <span
                  class="sub-arrow"
                  v-if="menuItem.children || menuItem.megamenu"
                ></span>
              </NuxtLink> -->
                <ul :ref="(el) => menuseStore.setMenuRefs(menuItem.title, el)" class="nav-submenu"
                  :class="{ opensubmenu: isActive(menuItem.title) }" v-if="menuItem.children">
                  <li class="border-bottom" :id="`li-nav-${childrenItem.key}`"
                    v-for="(childrenItem, index) in menuItem.children" :key="index">

                    <a href="#" @click="setActiveChild(childrenItem.title)"
                      v-if="childrenItem.children">
                      <!-- {{ childrenItem.title }} -->
                      {{ $t(`menu.${childrenItem.key}`, childrenItem.title) }}
                      <span class="sub-arrow " v-if="childrenItem.children"></span>
                    </a>
                    <template v-else>
                      <!-- <a :href="childrenItem.path" v-if="childrenItem?.legacy && false"
                      @click="setActiveChild(childrenItem.title)">
                      {{ $t(`menu.${childrenItem.key}`, childrenItem.title) }}
                    </a> -->
                      <NuxtLink
                        @click="setActiveAndUpdateState({ menuChildItem: childrenItem.title, query: childrenItem.query, path: childrenItem.path, })" href="#">
                        {{ $t(`menu.${childrenItem.key}`, childrenItem.title) }}
                      </NuxtLink>
                    </template>

                    <ul :id="`child-ul-${childrenItem.title}-${index}`"
                      :ref="(el) => menuseStore.setMenuRefs(`child-ul-${menuItem.title}-${index}`, el)"
                      class="nav-sub-childmenu" :class="{ opensubchild: isActiveChild(childrenItem.title) }"
                      v-if="childrenItem.children">
                      <li class="border-bottom" :id="childrenSubItem.key"
                        v-for="(childrenSubItem, index) in childrenItem.children" :key="index">
                        <NuxtLinkLocale :to="{ path: childrenSubItem.path }">
                          {{
                            $t(
                              `menu.${childrenSubItem.key}`,
                              childrenSubItem.title
                            )
                          }}
                        </NuxtLinkLocale>
                      </li>
                    </ul>
                  </li>
                </ul>
                <div class="mega-menu-container" :class="{ opensubmenu: isActive('portfolio') }"
                  v-if="menuItem.megamenu">
                  <div class="container">
                    <div class="row">
                      <div class="col mega-box" v-for="(childrenItem, index) in menuItem.children" :key="index">
                        <div class="link-section">
                          <div class="menu-title" @click="setActivesubmega('portfolio')">
                            <h5>
                              {{ childrenItem.title }}
                              <span class="sub-arrow"></span>
                            </h5>
                          </div>
                          <div class="menu-content" :class="{
                            opensubmegamenu: isActivesubmega('portfolio'),
                          }">
                            <ul>
                              <li v-for="(
                                childrenSubItem, index
                              ) in childrenItem.children" :key="index">
                                <NuxtLinkLocale :to="{ path: childrenSubItem.path }">
                                  {{ childrenSubItem.title }}
                                </NuxtLinkLocale>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </li>
          </ul>
        </OnClickOutside>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useMenuStore } from "~/store/menu";
import { useI18n } from "vue-i18n";
import { OnClickOutside } from "@vueuse/components/index";
import { useUserStore } from "~/store/UserStore";
import { useFilterStore } from "~/store/FilterStore";
import { useCategoryStore } from "~/store/CategoryStore";
import promoQueriesJson from "~~/data/promo.query.json";
import { useUtilityStore } from "~/store/UtililityStore";
const userStore = useUserStore();
const filterStore = useFilterStore();
const categoryStore = useCategoryStore();
const utilityStore = useUtilityStore();
const isAuthenticated = computed(() => userStore.isAuthenticated)
const selectedLanguage = computed(() => utilityStore.selectedLanguage);
const parentCategoriesWithPromo = computed(() => categoryStore.parentCategoriesWithPromo);

const promoChilds = computed(() => parentCategoriesWithPromo.value?.map(m => {
  return {
    "path": "/products/search",
    "key": m.key,
    "title": promoQueries.value[m.key].labels[selectedLanguage.value] + " +" + m.doc_count,
    "type": "link",
    query: promoQueries.value[m.key]
  }
}))

const calenderData = ref([
  {
    key: 'event1',
    label: 'Event One',
    image: 'https://example.com/event1.jpg',
  },
  {
    key: 'event2',
    label: 'Event Two',
    image: 'https://example.com/event2.jpg',
  },
  {
    key: 'event3',
    label: 'Event Three',
    image: 'https://example.com/event3.jpg',
  }
]);
const calenderChilds = computed(() => calenderData.value?.map(c => {
  return {
    "path": "/calender",
    "key": c.key,
    "title": c.label,
    "type": "link",
    "image": c.image
  }
}))


const props = defineProps(["leftSidebarVal"]);
const router = useRouter();
const promoQueries = ref(promoQueriesJson)
const openmobilenav = ref(false);
const subnav = ref(false);
const activeItem = ref("home");
const activeChildItem = ref("");
const activemegaChild = ref("portfolio");
const menuseStore = useMenuStore();
const menulist = computed(() => menuseStore.data);

const localePath = useLocalePath()

const { locale } = useI18n();

const isActive = (menuItem) => {
  return activeItem.value === menuItem;
};
const setActive = (menuItem) => {
  if (activeItem.value === menuItem) {
    activeItem.value = "";
  } else {
    activeItem.value = menuItem;
  }
};
const isActiveChild = (menuChildItem) => {
  return activeChildItem.value === menuChildItem;
};
const setActiveChild = (menuChildItem) => {
  if (activeChildItem.value === menuChildItem) {
    activeChildItem.value = "";
  } else {
    activeChildItem.value = menuChildItem;
  }
};
const setActiveAndUpdateState = ({ menuChildItem, query, path, }) => {
  if (activeChildItem.value === menuChildItem) {
    activeChildItem.value = "";
  } else {
    activeChildItem.value = menuChildItem;
  }

  setTimeout(() => {
    if (query) {
      if (query.attribute) {
        filterStore.setSelectedAttributeFilters(typeof query.attribute === "string" ? JSON.parse(query.attribute) : query.attribute)
        filterStore.setSelectedReleaseFilters(null)
        filterStore.setSelectedCategoryFilters([])
      }
      if (query.categories && query.categories.length) {
        filterStore.setSelectedCategoryFilters(query.categories)
        filterStore.setSelectedReleaseFilters(null)
        // filterStore.setSelectedAttributeFilters({})
      }
      if (query.release) {
        filterStore.setSelectedReleaseFilters(query.release)
        filterStore.setSelectedAttributeFilters({})
        filterStore.setSelectedCategoryFilters([])
      }

      if (query.expandedCategories) {
        filterStore.setExpandedCategoryFilters(query.expandedCategories)
      }
      if (query.categorySelected) {
        filterStore.setCategoryfilterState(query.categorySelected)
      }
    }
  }, 100);
  

  router.push(localePath(path))
  //   navigateTo({ name: 'products-search' })

};


const redirect = (childrenItem) => {
  if (activeChildItem.value === childrenItem.title) {
    activeChildItem.value = "";
  } else {
    activeChildItem.value = childrenItem.title;
  }

  const queryprams = {};

  if (childrenItem.query) {
    queryprams["data"] = JSON.stringify(childrenItem.query);
    router.push({
      path: localePath(childrenItem.path),
      query: queryprams,
    });
  } else {
    router.go({
      path: childrenItem.path,
    });
  }
};
const isActivesubmega = (megaChildItem) => {
  return activemegaChild.value === megaChildItem;
};
const setActivesubmega = (megaChildItem) => {
  if (activemegaChild.value === megaChildItem) {
    activemegaChild.value = "";
  } else {
    activemegaChild.value = megaChildItem;
  }
};



onBeforeMount(() => {
  menuseStore.addChildToMenu(
    'release',
  )


})
onMounted(() => {
  menuseStore.addChildToCalender("calender", calenderChilds)
  setTimeout(() => {

    menuseStore.addChildToPromo("promo", promoChilds)
  }, 1000);
})


const close = () => {
  openmobilenav.value = false;
};


</script>

<style lang="scss" scoped>
.toggle-nav {
  &.toggle-button {
    z-index: 1;
  }
}

.nav-link {
  padding: 10px 20px !important;
  margin: 0 5px !important;
}
</style>
